import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, debounce, debounceTime, filter, switchMap, tap } from 'rxjs/operators';
import { AttachPaymentMethodError, CannotDeleteLicenseManager, CantUnsetLastLicenseManager, EditUserNameOrEmailForOneItem, NoLicenseAttached, 
    NotEnoughSeatsPurchased, NO_CARD, OtpExpired, OtpInvalid, OtpRequestRateExceeded, UnableToStartTrial, UnknownError, UnknownProductCode, UnknownUser, 
    UserEmailExists, UserEmailExistsInOtherLicense, UserInactive } from '../constants/error.constants';
import { environment as env } from 'src/environments/environment';
import { AccessCodeService } from '../services/access-code.service';
import { MatDialog } from '@angular/material/dialog';
import { GpPopupDialogComponent } from '../components/dialogs/gp-popup-dialog/gp-popup-dialog.component';
import { TenantIdService } from '../services/tenant-id.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    errorInterpretations: Record<string, { text: string; reroute?: string }> = {
        [UnknownError]: { text: 'Uh oh... server error. Please contact support@definely.com for help' },
        [NO_CARD]: { text: 'Please enter card information', reroute: '/billing' },
        [UnknownProductCode]: { text: 'Invalid trial product' },
        [UserInactive]: { text: 'User deactivated' },
        [UnknownUser]: { text: 'User not found, please visit the product pricing page or start a trial' },
        [OtpExpired]: { text: 'Login code expired' },
        [OtpInvalid]: { text: 'Login code is incorrect, please try again' },
        [NoLicenseAttached]: { text: 'No licence attached, please contact support' },
        [UnableToStartTrial]: { text: 'Unable to start trial, please contact support' },
        [UserEmailExists]: { text: 'User with that email is already added' },
        [UserEmailExistsInOtherLicense]: { text: 'That email is already added to another license' },
        [EditUserNameOrEmailForOneItem]: { text: 'Emails and Names are only editable for single user' },
        [CannotDeleteLicenseManager]: { text: 'Can\'t delete licence manager' },
        [NotEnoughSeatsPurchased]: { text: 'Not enough seats purchased' },
        [AttachPaymentMethodError]: { text: 'Can\'t use specified card, some of the info was incorrect' },
        [CantUnsetLastLicenseManager]: { text: 'Sorry you need to have at least one Licence Manager assigned to this account' },
        [OtpRequestRateExceeded]: { text: 'You\'ve exceeded the access code request limit, please wait a few minutes and try again.'}
    };

    private unauthorizedObservable = new BehaviorSubject<number>(0);

    constructor(private toastrService: ToastrService,
        private router: Router,
        private accessCodeService: AccessCodeService,
        public dialog: MatDialog,
        private route: ActivatedRoute,
        private tenantIdService: TenantIdService
    ) {

        this.unauthorizedObservable.pipe(
            filter(x => x == 403),
            debounceTime(500),
            switchMap(() => 
                this.dialog.open(GpPopupDialogComponent, { data: { text: 'You have no access to the License Manager app' } })
                    .afterClosed()
            ),
            switchMap(_ => {
                return this.tenantIdService.tenantId
                    .pipe(
                        tap(tenantIdInfo => {
                            let tenantIdPath = tenantIdInfo.source == 'path' ? '/t/' + tenantIdInfo.name : '/';
                            return this.router.navigate([tenantIdPath]);
                        })
                    );
            })
        )
        .subscribe();
    }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((err) => {
                if (env.errorsToConsole)
                    console.log(err);

                if (err instanceof HttpErrorResponse) {
                    try {
                        let errorText = err.statusText;

                        if (err.status === 0) {
                            this.toastrService.error('Connection problem');
                            return throwError(err);
                        }

                        const tenantId = this.route.snapshot.paramMap.get('tenantId');

                        if (err.status === 401) {
                            this.accessCodeService.clearToken();
                            this.router.navigate(['/t', tenantId, '']);
                            return throwError(err);
                        }

                        if (err.status === 403) {
                            this.unauthorizedObservable.next(403);
                            this.accessCodeService.clearToken();
                            this.router.navigate(['/t', tenantId, '']);
                            return throwError(err);
                        }

                        if (err.status === 400 && err.error?.error === 'invalid_grant'){
                            this.accessCodeService.clearToken();
                            this.router.navigate(['/t', tenantId, '']);
                            return throwError(err);
                        }

                        if (err?.error?.code) {
                            const interpretation = this.errorInterpretations[err.error.code];

                            if (!interpretation) {
                                this.toastrService.error('Uh oh... server error. Please contact support for help');
                            } else {
                                this.toastrService.error(interpretation.text);

                                if (interpretation.reroute) {
                                    this.router.navigateByUrl(interpretation.reroute);
                                }
                            }

                            return throwError(err);
                        }

                        this.toastrService.error(err.message, errorText);
                    } catch (e) {
                        this.toastrService.error('Unexpected error. Please contact support');
                    }
                }

                return of(err);
            })
        );
    }

}
